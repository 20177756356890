import type { HandleClientError } from '@sveltejs/kit';

export const handleError = (({ error, event }) => {
	const errorId = Math.random().toString(36).slice(2, 15);
	console.trace('error', error, event);

	return {
		message: error.toString(),
		errorId,
	};
}) satisfies HandleClientError;